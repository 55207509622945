import React, { useState, useEffect } from 'react'
import { Card, Table, Tag, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { listDeliveries } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import SearchComponent from '../../../components/searchBar';

const client = generateClient();

const Requests = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries,] = useState([]);
  const navigate = useNavigate();
  const renderRequestStatus = (requestStatus) => {
    if (requestStatus === "ACCEPTED") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "PENDING") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "DECLINED") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() =>{
    fetchDeliveries();
  }, [client]);

  const fetchDeliveries = async () =>{
    try{
      const deliveryData = await client.graphql({
        query: listDeliveries,
      });
      const formattedDeliveries = deliveryData.data.listDeliveries.items;
      setDeliveries(formattedDeliveries);
      setFilteredDeliveries(formattedDeliveries)
    }
    catch(error){
      console.error("Failed to fetch Delivery Requests", error);
    }
  }

const handleSearch = (searchTerm) => {
  const filtered = deliveries.filter(delivery =>
    delivery.origin.toLowerCase().includes(searchTerm.toLowerCase())
  );
  setFilteredDeliveries(filtered);
};

  const tableColumns = [
    {
      title: "delivery ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Pickup Location",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "Delivery Location",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Delivery Price",
      dataIndex: "deliveryFee",
      key: "deliveryFee",
      render: (price) => <span>{`Ksh ${price}`}</span>,
    },
    {
      title: "Delivery Status",
      dataIndex: "status",
      key: "status",
      render: renderRequestStatus,
    },
  ]; 

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Deliveries</h2>
        <SearchComponent
          placeholder="Search by location"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => navigate("/add-delivery")}>
          Create Delivery
        </Button>
      </div>
    <Card title={"Deliveries"} style={{ margin: 20 }}>
      <Table dataSource={filteredDeliveries} columns={tableColumns} rowKey="id"
        onRow={(requestItem) => ({
          onClick: () => {
            navigate(`delivery/${requestItem.id}`);
          },
        })}
      />
    </Card>
    </div>
  );
};

export default Requests;
