import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, Select } from "antd";
import { listBatteries } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';

const client = generateClient();

const Batteries = () => {
  const [batteries, setBatteries] = useState([]);
  const [filteredBatteries, setFilteredBatteries] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const renderBatteryStatus = (batteryStatus) => {
    if (batteryStatus === "charged") {
      return <Tag color={"green"}>{batteryStatus}</Tag>;
    }
    if (batteryStatus === "in use") {
      return <Tag color={"blue"}>{batteryStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchBatteries();
    checkUserRole();
  }, []);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);
    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const extractFranchises = (batteries) => {
    const uniqueFranchises = [...new Set(batteries.map(battery => battery.franchise))];
    setFranchises(uniqueFranchises);
  };

  async function fetchBatteries() {
    let allBatteries = [];
    let nextToken = null;
    do {
      const batteryData = await client.graphql({
        query: listBatteries,
        variables: { limit: 1000, nextToken: nextToken }
      });
      allBatteries = [...allBatteries, ...batteryData.data.listBatteries.items];
      nextToken = batteryData.data.listBatteries.nextToken;
    } while (nextToken);
      setBatteries(allBatteries);
      setFilteredBatteries(allBatteries);
      extractFranchises(allBatteries);
  }

  const handleFranchiseChange = (value) => {
    setSelectedFranchise(value);
    if (value) {
      const filtered = batteries.filter(battery => battery.franchise === value);
      setFilteredBatteries(filtered);
    } else {
      setFilteredBatteries(batteries);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = batteries.filter(battery =>
      battery.number.toLowerCase().includes(searchTerm.toLowerCase()) ||
      battery.status.toLowerCase().includes(searchTerm)
    );
    setFilteredBatteries(filtered);
  };

  const tableColumns = [
    {
      title: "Battery Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Bike Type",
      dataIndex: "bike",
      key: "bike",
    },
    {
      title: "Current Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Battery Ownership",
      dataIndex: "batteryOwnership",
      key: "batteryOwnership",
    },
    {
      title: "Battery Status",
      dataIndex: "status",
      key: "status",
      render: renderBatteryStatus,
    },
  ];

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Batteries</h2>

        <SearchComponent
          placeholder="Search by battery number, status"
          onSearch={handleSearch}
        />

        {isAdmin && (
          <Button type="primary" onClick={() => navigate("/add-battery")}>
            Add Battery
          </Button>
        )}
      </div>
      <Card title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>Batteries List</span>
          <Select
            style={{ width: 200, marginRight: 20 }}
            placeholder="Filter by franchise"
            onChange={handleFranchiseChange}
            allowClear
          >
            {franchises.map(franchise => (
              <Select.Option key={franchise} value={franchise}>{franchise}</Select.Option>
            ))}
          </Select>
        </div>
      }
        extra={isAdmin && (
          <Tooltip title="Download as Excel">
            <DownloadOutlined onClick={() => downloadExcel(filteredBatteries, "batteries")}
              style={{ fontSize: '20px', cursor: 'pointer' }}
            />
          </Tooltip>
        )}
        style={{ margin: 20 }}>
        <Table dataSource={filteredBatteries} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`battery/${requestItem.id}`);
            },
          })} />
      </Card>
    </div>

  );
};

export default Batteries;
