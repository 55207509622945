import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, DatePicker, Space } from "antd";
import { listDispatches } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const client = generateClient();
const { RangePicker } = DatePicker;

const Dispatch = () => {
  const [dispatches, setDispatches] = useState([]);
  const [filteredDispatches, setFilteredDispatches] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const renderDispatchStatus = (requestStatus) => {
    if (requestStatus === "success") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "failed") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchDispatches();
    checkUserRole();
  }, []);

  useEffect(() => {
    filterDispatches();
  }, [dispatches, searchTerm, dateRange]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchDispatches() {
    let allDispatches = [];
    let nextToken = null;
    try {
      do {
        const dispatchData = await client.graphql({ 
          query: listDispatches,
          variables: { limit: 1000, nextToken: nextToken }
        });
        allDispatches = [...allDispatches, ...dispatchData.data.listDispatches.items];
        nextToken = dispatchData.data.listDispatches.nextToken;
      } while (nextToken);
  
      const formattedDispatches = allDispatches.map(dispatch => ({
        ...dispatch,
        createdAt: dayjs(dispatch.createdAt).tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss')
      }));
  
      const sortedDispatches = formattedDispatches.sort((a, b) => 
        dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
      );
  
      setDispatches(sortedDispatches);
      setFilteredDispatches(sortedDispatches);
    } catch (error) {
      console.error("Error fetching dispatches:", error);
    }
  }  

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };
 
  const filterDispatches = () => {
    let filtered = [...dispatches];

    if (searchTerm) {
      filtered = filtered.filter(dispatch =>
        (dispatch.batteryInNumber && dispatch.batteryInNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.batteryOutNumber && dispatch.batteryOutNumber.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.franchise && dispatch.franchise.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (dispatch.riderName && dispatch.riderName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(dispatch => {
        const dispatchDate = dayjs(dispatch.createdAt);
        return dispatchDate.isAfter(dateRange[0].startOf('day')) && dispatchDate.isBefore(dateRange[1].endOf('day'));
      });
    }

    setFilteredDispatches(filtered);
  };

  const tableColumns = [
    {
      title: "Battery Out",
      dataIndex: "batteryOutNumber",
      key: "batteryOutNumber",
    },
    {
      title: "Bike Type",
      dataIndex: "bike",
      key: "bike",
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "riderName",
    },
    {
      title: "Phone Number",
      dataIndex: "riderPhoneNumber",
      key: "riderPhoneNumber",
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Time Dispatched",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderDispatchStatus,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Dispatches</h2>
        <SearchComponent
          placeholder="Search by battery number"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => navigate("/add-dispatch")}>
          Add Dispatch
        </Button>
      </div>
      <Card title="Dispatch List" extra={
          <Space>
            <RangePicker onChange={handleDateRangeChange} />
            {isAdmin && (
              <Tooltip title="Download as Excel">
                <DownloadOutlined 
                  onClick={() => downloadExcel(filteredDispatches, "dispatches")}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </Space>
        } 
        style={{ margin: 20 }}
      >
        <Table dataSource={filteredDispatches} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`/Dispatch/${requestItem.id}`);
            },
          })} />
      </Card>
    </div>
  );
};

export default Dispatch;