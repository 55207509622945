import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
import { updateBattery, createDispatch } from "../../../../graphql/mutations";
import { listOperators, listFranchises, listBatteries, listRiders} from '../../../../graphql/queries'

const { Option } = Select;

const filterOption = (input, option) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const AddDispatch = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = location.state?.dispatch;
  const client = generateClient();
  const [isLoading, setIsLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [allBatteries, setAllBatteries] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [riders, setRiders] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [selectedBikeType, setSelectedBikeType] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const franchisesResponse = await client.graphql({ query: listFranchises });
      setFranchises(franchisesResponse.data.listFranchises.items || []);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchOperators = async () => {
      if (selectedFranchise) {
        const operatorsResponse = await client.graphql({
          query: listOperators,
          variables: { filter: { franchise: { eq: selectedFranchise } } }
        });
        setOperators(operatorsResponse.data.listOperators.items || []);
      }
    };

    fetchOperators();
  }, [selectedFranchise]);

  useEffect(() => {
    const fetchBatteries = async () => {
      if (selectedBikeType) {
        let allBatteries = [];
        let nextToken = null;
        do {
          const batteriesResponse = await client.graphql({
            query: listBatteries,
            variables: {
              filter: {
                and: [
                  { status: { eq: "charged" } },
                  { bike: { eq: selectedBikeType } }
                ]
              },
              limit: 1000,
              nextToken: nextToken
            }
          });
          const fetchedBatteries = batteriesResponse.data.listBatteries.items || [];
          allBatteries = [...allBatteries, ...fetchedBatteries];
          nextToken = batteriesResponse.data.listBatteries.nextToken;
        } while (nextToken);
        
        setBatteries(allBatteries);
      }
    };

    fetchBatteries();
  }, [selectedBikeType]);

  useEffect(() => {
    const fetchAllRiders = async () => {
      try {
        let allRiders = [];
        let nextToken = null;
        let filter = {};
        if (selectedPlatform) {
          filter.fleet = { eq: selectedPlatform };
        }
        if (selectedBikeType) {
          filter.vehicleType = { eq: selectedBikeType };
        }

        do {
          const ridersResponse = await client.graphql({
            query: listRiders,
            variables: { 
              filter: filter,
              limit: 1000,
              nextToken: nextToken
            }
          });

          const fetchedRiders = ridersResponse.data.listRiders.items || [];
          allRiders = [...allRiders, ...fetchedRiders];
          nextToken = ridersResponse.data.listRiders.nextToken;

        } while (nextToken);

        setRiders(allRiders);
      } catch (error) {
        console.error('Error fetching riders:', error);
        toast.error("Failed to fetch riders");
      }
    };

    fetchAllRiders();
  }, [selectedPlatform, selectedBikeType]);

  useEffect(() => {
    if (dispatch) {
      form.setFieldsValue({
        riderName: dispatch.riderName,
        bike: dispatch.bike,
        batteryNumberOut: dispatch.batteryNumberOut,
        franchise: dispatch.franchise,
        platform: dispatch.platform,
        status: dispatch.status,
      });
    }
  }, [dispatch, form]);

  const handleRiderChange = (riderId) => {
    const selectedRider = riders.find(rider => rider.id === riderId);
    if (selectedRider) {
      form.setFieldsValue({
        riderName: selectedRider.name,
        riderPhoneNumber: selectedRider.phone_number
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);

      const batteryOut = batteries.find(b => b.number === values.batteryOut);

      if (!batteryOut) {
        throw new Error("Selected battery not found");
      }

      const dispatchData = {
        batteryOutNumber: values.batteryOut,
        bike: values.bike,
        riderName: values.riderName,
        riderPhoneNumber: values.riderPhoneNumber,
        operatorName: values.operatorName,
        platform: values.platform,
        franchise: values.franchise,
        status: "success",
        reference: `FREE-DISPATCH-${Date.now()}`,
      };

      await createDispatchAndUpdateBattery(dispatchData, batteryOut);

      toast.success("Free dispatch processed successfully!");
      setIsLoading(false);
      navigate("/dispatch");
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setIsLoading(false);
      toast.error(error.message || "An error occurred while processing the dispatch");
    }
  };

  const createDispatchAndUpdateBattery = async (dispatchData, batteryOut) => {
    const client = generateClient();
  
    // Update the outgoing battery
    await client.graphql({
      query: updateBattery,
      variables: {
        input: {
          id: batteryOut.id,
          status: "in use"
        }
      }
    });
  
    // Create the dispatch
    await client.graphql({
      query: createDispatch,
      variables: { input: dispatchData }
    });
  };

  const isEditMode = !!dispatch;
  const headingText = isEditMode ? "Edit Dispatch Details Below:" : "Enter Dispatch Details Below:";

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", maxWidth: "600px", margin: "auto" }}>
      <h3 style={{ marginTop: 20 }}>{headingText}</h3>
      <Form
        form={form}
        name="dispatchForm"
        onFinish={handleSubmit}
        style={{ width: "70%" }}
      >
        <Form.Item
          name="franchise"
          rules={[{ required: true, message: "Please select the franchise location!" }]}
        >
          <Select
            placeholder="Franchise Location"
            onChange={(value) => setSelectedFranchise(value)}
            showSearch
            filterOption={filterOption}
          >
            {franchises.map((franchise) => (
              <Option key={franchise.id} value={franchise.location}>
                {franchise.location}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="bike"
          rules={[{ required: true, message: "Please input the battery's bike type!" }]}
        >
          <Select
            placeholder="Bike Type"
            onChange={(value) => setSelectedBikeType(value)}
            showSearch
            filterOption={filterOption}
          >
            <Option value="Shujaa">Shujaa</Option>
            <Option value="Shujaa2">Shujaa 2.0</Option>
            <Option value="Jasiri">Jasiri</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="batteryOut"
          rules={[{ required: true, message: "Please enter the number of the battery out!" }]}
        >
          <Select
            placeholder="Battery Out Number"
            disabled={ !selectedBikeType}
            showSearch
            filterOption={filterOption}
          >
            {batteries.map((battery) => (
              <Option key={battery.id} value={battery.number}>
                {battery.number}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="operatorName"
          rules={[{ required: true, message: "Please enter the operator's name!" }]}
        >
          <Select 
            placeholder="Operator Name"
            disabled={!selectedFranchise}
            showSearch
            filterOption={filterOption}
          >
            {operators.map((operator) => (
              <Option key={operator.id} value={operator.name}>
                {operator.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="platform"
          rules={[{ required: true, message: "Please input the rider platform!" }]}
        >
          <Select
            placeholder="Platform"
            onChange={(value) => setSelectedPlatform(value)}
            showSearch
            filterOption={filterOption}
          >
            <Option value="Bolt">BOLT</Option>
            <Option value="Kutuma">KUTUMA</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="riderName"
          rules={[{ required: true, message: "Please select a rider!" }]}
        >
          <Select
            placeholder="Select Rider"
            onChange={handleRiderChange}
            disabled={!selectedPlatform || !selectedBikeType}
            showSearch
            filterOption={filterOption}
          >
            {riders.map((rider) => (
              <Option key={rider.id} value={rider.id}>
                {rider.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="riderPhoneNumber"
          rules={[
            { required: true, message: "Please input the phone number!" },
          ]}
        >
          <Input placeholder="Rider Phone Number" disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Processing Dispatch" : (isEditMode ? "Update Dispatch" : "Confirm Dispatch")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddDispatch;