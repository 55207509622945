import React, { useState, useEffect } from "react";
import { Card, Table } from "antd";
import { listCustomers } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import SearchComponent from "../../../components/searchBar";

const client = generateClient();

const Customers = () => {

  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    fetchCustomers();
  }, [client]);

  async function fetchCustomers() {
    try {
      const userData = await client.graphql({ query: listCustomers });
      const formattedCustomers = userData.data.listCustomers.items;
      setCustomers(formattedCustomers);
      setFilteredCustomers(formattedCustomers)
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }
  const handleSearch = (searchTerm) => {
    const filtered = customers.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCustomers(filtered);
  };

  const tableColumns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Customers</h2>
        <SearchComponent
          placeholder="Search by customer name"
          onSearch={handleSearch}
        />
      </div>
      <Card title="Customers" style={{ margin: 20 }}>
        <Table dataSource={filteredCustomers} columns={tableColumns} rowKey="id" />
      </Card>
    </div>

  );
};

export default Customers;
