// downloadExcel.js
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

/**
 * Downloads the given data as an Excel file.
 * @param {Array} data - The data to be downloaded.
 * @param {string} fileName - The filename to use for the downloaded file.
 */
export default function downloadExcel(data, fileName) {
  // Convert array of objects to worksheet
  const ws = XLSX.utils.json_to_sheet(data);

  // Generate workbook and add worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Generate workbookout as a base64 string
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // Changed 'type' to 'array'

  // Convert to blob and use file-saver to save
  const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  saveAs(blob, `${fileName}.xlsx`);
}
