
import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip } from "antd";
import { listFranchises, listBatteries } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const Franchises = () => {
  const [franchises, setFranchises] = useState([]);
  const [filteredFranchises, setFilteredFranchises] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const renderBatteryCount = (count) => {
    if (count === 0) {
      return <Tag color={"red"}>{count}</Tag>;
    }
    if (count >= 1) {
      return <Tag color={"green"}>{count}</Tag>;
    }
  };

  useEffect(() => {
    fetchFranchises();
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchFranchises() {
    try {
      const franchiseData = await client.graphql({ query: listFranchises });
      const batteriesData = await client.graphql({ query: listBatteries });

      const batteries = batteriesData.data.listBatteries.items;

      const formattedFranchises = franchiseData.data.listFranchises.items.map(franchise => {
        const franchiseBatteries = batteries.filter(battery => battery.franchise === franchise.location);
        return {
          ...franchise,
          batteryCount: franchiseBatteries.length
        };
      });

      setFranchises(formattedFranchises);
      setFilteredFranchises(formattedFranchises);
    } catch (error) {
      console.error("Error fetching franchises:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = franchises.filter(franchise =>
      franchise.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      franchise.batteryCount.toString().includes(searchTerm)
    );
    setFilteredFranchises(filtered);
  };

  const tableColumns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Battery Count",
      dataIndex: "batteryCount",
      key: "batteryCount",
      render: renderBatteryCount,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Franchises</h2>
        <SearchComponent
          placeholder="Search by location"
          onSearch={handleSearch}
        />
        {isAdmin && (
          <Button type="primary" onClick={() => navigate("/add-franchise")}>
            Add Franchise
          </Button>
        )}
      </div>
      <Card title="Franchises List" extra={isAdmin && (
        <Tooltip title="Download as Excel">
          <DownloadOutlined onClick={() => downloadExcel(filteredFranchises, "franchises")}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
        </Tooltip>)}
        style={{ margin: 20 }}>
        <Table dataSource={filteredFranchises} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`franchise/${requestItem.id}`);
            },
          })} />
      </Card>
    </div>

  );
};

export default Franchises;
