import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { signIn, confirmSignIn, getCurrentUser } from 'aws-amplify/auth';
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SignInForm = ({ onSuccessfulSignIn }) => {
  const [form] = Form.useForm();
  const [mfaCode, setMfaCode] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        if (user) {
          toast.info("You're already signed in");
          onSuccessfulSignIn();
          navigate("/");
        }
      } catch (error) {
        // User is not signed in, do nothing
      }
    };
    checkCurrentUser();
  }, [navigate, onSuccessfulSignIn])

  const handleSubmit = async (values) => {
    try {
      const { isSignedIn, nextStep } = await signIn({
        username: values.phoneNumber,
        password: values.password,
        options: {
          authFlowType: "USER_PASSWORD_AUTH",
        },
      });

      if (isSignedIn) {
        toast.success("Sign-in successful");
        onSuccessfulSignIn();
        navigate("/");
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
        toast.success("A verification code has been sent to your phone number. Please enter it to complete sign-in.");
        setShowCodeInput(true);
      }
    } catch (error) {
      // console.error("Detailed sign-in error:", error);
      if (error.name === 'UserNotFoundException') {
        setErrorMessage("We couldn't find an account with that phone number. Please check and try again.");
      } else {
        setErrorMessage(error.message || "Sign in failed. Please try again.");
      }
    }
  }

  const handleMFASubmit = async () => {
    try {
      setErrorMessage('');
      await confirmSignIn({
        challengeResponse: mfaCode,
      });

      const currentUser = await getCurrentUser();
      if (currentUser) {
        toast.success("Verification successful. Welcome!");
        onSuccessfulSignIn();
        navigate("/");
      } else {
        throw new Error("Failed to retrieve current user after verification");
      }
    } catch (error) {
      // console.error("An error occurred during verification", error);
      setErrorMessage(error.message || "Verification failed. Please try again.");
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", height: "100vh" }}>
      <h3>Sign In</h3>
      {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
      <Form
        form={form}
        name="signinForm"
        onFinish={handleSubmit}
        initialValues={{
          password: '',
        }}
        style={{ width: "30%" }}
      >
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: "Please input your phone number!" }]}
        >
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="KE"
            style={{ width: '100%' }}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" />
        </Form.Item>
        {!showCodeInput && (
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
              <Button type="primary" htmlType="submit">
                Sign In
              </Button>
              {/* <Button onClick={() => navigate("/signup")}>
                Don't have an account? Sign Up
              </Button> */}
            </div>
          </Form.Item>
        )}
        {showCodeInput && (
          <>
            <Form.Item
              name="mfaCode"
              rules={[{ required: true, message: "Please input the verification code!" }]}
            >
              <Input
                prefix={<LockOutlined />}
                placeholder="Verification Code"
                onChange={(e) => setMfaCode(e.target.value)}
                maxLength={6}
              />
            </Form.Item>
            <Form.Item>
              <Button onClick={handleMFASubmit} type="primary">
                Verify Code
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
      <ToastContainer />
    </div>
  );
};

export default SignInForm;