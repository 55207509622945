import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Divider } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
import { createBattery, updateBattery } from "../../../../graphql/mutations";
import { getBattery, listFranchises, listBatteries } from "../../../../graphql/queries";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';
import ExcelUploader from "../../../../components/upload";
import * as XLSX from 'xlsx';

const { Option } = Select;

const BatteryForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { batteryID } = useParams();
  const client = generateClient();
  const [franchises, setFranchises] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const franchisesResponse = await client.graphql({ query: listFranchises });
      setFranchises(franchisesResponse.data.listFranchises.items || []);
      if (batteryID) {
        setIsEditMode(true);
        const { data } = await client.graphql({
          query: getBattery,
          variables: { id: batteryID },
        });
        const batteryData = data.getBattery;
        form.setFieldsValue({
          number: batteryData.number,
          bike: batteryData.bike,
          franchise: batteryData.franchise,
          status: batteryData.status,
          batteryOwnership: batteryData.batteryOwnership
        });
      }
    };

    fetchData();
  }, [batteryID, form]);

  useEffect(() => {
    checkUserRole();
  }, []);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);
    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);


      if (json.length === 0) {
        toast.error("The Excel file is empty or could not be parsed.");
        return;
      }


      // Update required columns to match your Excel file
      const requiredColumns = ['Battery Number', 'Bike Type', 'Franchise', 'Owner'];
      const headers = Object.keys(json[0]);
      const missingColumns = requiredColumns.filter(col => !headers.includes(col));

      if (missingColumns.length > 0) {
        toast.error(`Missing required columns: ${missingColumns.join(', ')}`);
        return;
      }

      try {
        let processedCount = 0;
        let skippedCount = 0;
        for (const row of json) {
          const batteryData = {
            number: row['Battery Number'],
            bike: row['Bike Type'],
            franchise: row['Franchise'],
            batteryOwnership: row['Owner'],
            status: 'charged' // Default status is set here
          };

          if (!batteryData.number) {
            console.error("Battery data is missing number:", row);
            skippedCount++;
            continue;
          }


          // Check if battery with this number already exists
          const existingBatteriesResponse = await client.graphql({
            query: listBatteries,
            variables: { filter: { number: { eq: batteryData.number } } }
          });

          const existingBatteries = existingBatteriesResponse.data.listBatteries.items;

          if (existingBatteries.length === 0) {
            // Create new battery entry
            try {
              await client.graphql({
                query: createBattery,
                variables: { input: batteryData },
              });
              processedCount++;
            } catch (error) {
              console.error("Error creating battery:", error);
              skippedCount++;
            }
          } else {
            console.warn(`Battery with number ${batteryData.number} already exists. Skipping.`);
            skippedCount++;
          }
          setUploadProgress(Math.round(((processedCount + skippedCount) / json.length) * 100));
        }
        toast.success(`Batteries uploaded successfully. Processed: ${processedCount}, Skipped: ${skippedCount}`);
        navigate("/batteries");
      } catch (error) {
        console.error("Error uploading batteries:", error);
        toast.error("An error occurred while uploading batteries");
      } finally {
        setUploadProgress(0);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (values) => {
    try {
      if (!isEditMode) {
        const existingBatteriesResponse = await client.graphql({
          query: listBatteries,
          variables: { filter: { number: { eq: values.number } } }
        });

        const existingBatteries = existingBatteriesResponse.data.listBatteries.items;

        if (existingBatteries.length > 0) {
          toast.error("A battery with this number already exists. Please use a different number.");
          return;
        }
      }

      const batteryData = isEditMode
        ? isAdmin
          ? {
            id: batteryID,
            number: values.number,
            bike: values.bike,
            franchise: values.franchise,
            batteryOwnership: values.batteryOwnership,
            status: values.status,
          }
          : { id: batteryID, status: values.status }
        : {
          number: values.number,
          bike: values.bike,
          franchise: values.franchise,
          batteryOwnership: values.batteryOwnership,
          status: 'charged',
        };

      if (isEditMode) {
        await client.graphql({
          query: updateBattery,
          variables: { input: batteryData }
        });
        toast.success("Battery updated successfully");
      } else {
        await client.graphql({
          query: createBattery,
          variables: { input: batteryData },
        });
        toast.success("Battery added successfully");
      }
      navigate("/batteries");
    }
    catch (error) {
      console.error(error);
      toast.error("An error occurred while processing the battery");
    }
  }

  const headingText = isEditMode ? "Update the Battery Below:" : "Add a Battery Below:";
  const buttonText = isEditMode ? "Update Battery" : "Add Battery";

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 10, alignItems: "center", height: "100vh" }}>
      <h3>{headingText}</h3>
      <Form
        form={form}
        name="batteryForm"
        onFinish={handleSubmit}
        initialValues={{
          number: "",
          bike: "Select Bike Type",
        }}
        style={{ width: "40%" }}
      >
        <Form.Item
          name="number"
          rules={[{ required: true, message: "Please input the battery number!" }]}
        >
          <Input placeholder="Battery Number" disabled={isEditMode && !isAdmin} />
        </Form.Item>
        <Form.Item
          name="bike"
          rules={[{ required: true, message: "Please input the battery's bike type!" }]}
        >
          <Select placeholder="Bike Type" disabled={isEditMode && !isAdmin}>
            <Option value="Shujaa">Shujaa</Option>
            <Option value="Shujaa2">Shujaa 2.0</Option>
            <Option value="Jasiri">Jasiri</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="franchise"
          rules={[{ required: true, message: "Please input the operator franchise!" }]}
        >
          <Select placeholder="Franchise Location" disabled={isEditMode && !isAdmin}>
            {franchises.length > 0 && franchises.map((franchise) => (
              <Option key={franchise.id} value={franchise.location}>
                {franchise.location}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="batteryOwnership"
          rules={[{ required: true, message: "Please select battery ownership!" }]}
        >
          <Select placeholder="Ownership">
            <Option value="eWAKA">eWAKA</Option>
            <Option value="Individual">Individual</Option>
          </Select>
        </Form.Item>
        {isEditMode && (
          <Form.Item
            name="status"
            rules={[{ required: true, message: "Please select battery status!" }]}
          >
            <Select placeholder="Battery Status">
              <Option value="charged">Charged</Option>
              <Option value="in use">In Use</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
      {!isEditMode && isAdmin && (
        <>
          <Divider>Or</Divider>
          <div>
            <h3>Upload Batteries</h3>
            <ExcelUploader onUpload={handleFileUpload} uploadProgress={uploadProgress} />
          </div>
        </>
      )}
    </div>
  );
};

export default BatteryForm;
