import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { getBattery } from "../../../../graphql/queries";
import { deleteBattery } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const DetailedBattery = () => {
  const { batteryID } = useParams();
  const navigate = useNavigate();
  const [battery, setBattery] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchBatteryDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getBattery,
          variables: { id: batteryID },
        });
        setBattery(data.getBattery);
      } catch (error) {
        console.error("Error fetching battery details:", error);
      }
    };

    fetchBatteryDetails();
  }, [batteryID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleDelete = async () => {
    try {
      await client.graphql({
        query: deleteBattery,
        variables: { input: { id: batteryID } },
      });
      toast.success("Battery deleted successfully");
      navigate('/batteries');
    } catch (error) {
      toast.error("Error deleting battery");
      console.error("Error deleting battery!", error);
    }
  };

  if (!battery) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Battery Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Battery Number">{battery.number}</Descriptions.Item>
        <Descriptions.Item label="Battery Status">{battery.status}</Descriptions.Item>
        <Descriptions.Item label="Bike Type">{battery.bike}</Descriptions.Item>
        <Descriptions.Item label="Ownsership">{battery.batteryOwnership}</Descriptions.Item>
        <Descriptions.Item label="Current Franchise">{battery.franchise}</Descriptions.Item>
        <Descriptions.Item label="Time Received">{battery.createdAt}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <Button block type="danger" size="large" onClick={handleDelete}>
            Remove Battery
          </Button>
        )}
        <Button block type="primary" size="large" onClick={() => navigate(`/edit-battery/${batteryID}`)}>
          Update Battery
        </Button>
      </div>
    </Card>
  );
};

export default DetailedBattery;
