import React from 'react';
import { Input } from 'antd';

const SearchComponent = ({ placeholder, onSearch }) => {
  return (
    <Input.Search
      placeholder={placeholder}
      onChange={(e) => onSearch(e.target.value)}
      style={{ width: 500 }}
    />
  );
};

export default SearchComponent;