import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { signUp, confirmSignUp, getCurrentUser } from 'aws-amplify/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const SignUpForm = () => {
  const [form] = Form.useForm();
  const [otpVisible, setOtpVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      await signUp({
        username: values.phoneNumber,
        password: values.password,
        options: {
          userAttributes: {
            email: values.email,
            name: values.name,
            phone_number: phoneNumber,
          },
        },
        autoSignIn: true
      });
      toast.success("Sign up successful");
      toast.success("OTP sent to your email");
      setOtpVisible(true);
    } catch (error) {
      toast.error("An error occurred while signing up");
    }
  }

  const handleVerifyCode = async (values) => {
    try {
      await confirmSignUp({
        username: values.phoneNumber,
        confirmationCode: values.code,
      });
      toast.success("Code verification successful");

     try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          toast.success("Successfully signed in");
          navigate("/add-swap");
        } else {
          // If not signed in, redirect to sign in page
          toast.info("Please sign in with your new account");
          navigate("/signin");
        }
      } catch (error) {
        // console.error("Error checking current user:", error);
        toast.info("Please sign in with your new account");
        navigate("/signin");
      }
    } catch (error) {
      // console.error("An error occurred while verifying code", error);
      toast.error("Code verification failed. Please try again.");
    }
  }
  
  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", height: "100vh" }}>
      <h3>Sign Up User Below:</h3>
      {!otpVisible ? (
        <Form
          form={form}
          name="signupForm"
          onFinish={handleSubmit}
          initialValues={{
            email: '',
            phoneNumber: '',
            password: '',
            confirmPassword: '',
          }}
          style={{ width: "35%" }}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: "Please input your phone number!" }]}
          >
          <PhoneInput
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={setPhoneNumber}
        defaultCountry="KE" // Set this to your default country
        style={{ width: '100%' }}
      />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: "Please confirm your password!" }]}
          >
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sign Up
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          form={form}
          name="otpForm"
          onFinish={handleVerifyCode}
          initialValues={{
            email: '',
            code: '',
          }}
          style={{ width: "35%" }}
        >
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" disabled />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: "Please input your phone number!" }]}
          >
            <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Phone Number" disabled />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[{ required: true, message: "Please input your OTP!" }]}
          >
            <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="OTP" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Verify OTP
            </Button>
          </Form.Item>
        </Form>
      )}
      <ToastContainer />
    </div>
  );
};

export default SignUpForm;
