import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Statistic, Typography, DatePicker } from 'antd';
import { generateClient } from 'aws-amplify/api';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { listBatteries, listSwaps } from '../../graphql/queries';
import { ArrowUpOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const [totalBatteries, setTotalBatteries] = useState(0);
  const [batteriesInUse, setBatteriesInUse] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSwaps, setTotalSwaps] = useState(0);
  const [swapData, setSwapData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const client = generateClient();

  useEffect(() => {
    fetchDashboardData();
  }, [dateRange]);

  const fetchDashboardData = async () => {
    try {
      // Fetch batteries
      const batteriesResponse = await client.graphql({ query: listBatteries });
      const batteries = batteriesResponse.data.listBatteries.items;
      setTotalBatteries(batteries.length);
      setBatteriesInUse(batteries.filter(battery => battery.status === 'in use').length);

      // Fetch swaps
      const swapsResponse = await client.graphql({ query: listSwaps });
      let swaps = swapsResponse.data.listSwaps.items;

      // Apply date range filter if set
      if (dateRange[0] && dateRange[1]) {
        swaps = swaps.filter(swap => {
          const swapDate = new Date(swap.createdAt);
          return swapDate >= dateRange[0] && swapDate <= dateRange[1];
        });
      }

      // Calculate total revenue and swaps
      const revenue = swaps.reduce((sum, swap) => sum + (swap.amount || 0), 0);
      setTotalRevenue(revenue);
      setTotalSwaps(swaps.length);

      // Prepare swap data for chart
      const swapCounts = swaps.reduce((acc, swap) => {
        const date = new Date(swap.createdAt).toISOString().split('T')[0]; // Use ISO date string
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      // Sort the dates and create the chart data
      const sortedDates = Object.keys(swapCounts).sort((a, b) => new Date(a) - new Date(b));
      const chartData = sortedDates.map(date => ({ date, count: swapCounts[date] }));
      setSwapData(chartData);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  return (
    <div style={{ padding: '24px' }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
        <Title level={2}>Battery Swap Dashboard</Title>
        <RangePicker onChange={handleDateRangeChange} />
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Revenue"
              value={totalRevenue}
              precision={2}
              prefix="KSH"
              valueStyle={{ color: '#3f8600' }}
              suffix={<ArrowUpOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Batteries"
              value={totalBatteries}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Batteries in Use"
              value={batteriesInUse}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Swaps"
              value={totalSwaps}
            />
          </Card>
        </Col>
      </Row>
      <div style={{ marginTop: '24px', height: '400px' }}>
        <Title level={3}>Swaps Over Time</Title>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={swapData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date"
              interval="preserveStartEnd"
              tickFormatter={(value) => new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
            />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="count" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;