import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import { getDispatch, getBattery } from "../../../../graphql/queries";
import { deleteDispatch, deleteBattery } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const DetailedDispatch = () => {
  const { dispatchID } = useParams();
  const navigate = useNavigate();
  const [dispatch, setDispatch] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchDispatchDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getDispatch,
          variables: { id: dispatchID },
        });
        setDispatch(data.getDispatch);
      } catch (error) {
        console.error("Error fetching battery details:", error);
      }
    };

    fetchDispatchDetails();
  }, [dispatchID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }
  const handleDelete = async () => {
    try {
      await client.graphql({
        query: deleteDispatch,
        variables: { input: { id: dispatchID } },
      });
      toast.success("Dispatch and battery deleted successfully");
      navigate('/dispatch');
    } catch (error) {
      toast.error("Error deleting dispatch");
      console.error("Error deleting dispatch!", error);
    }
  };

  if (!dispatch) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Dispatch Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Battery Out Number">{dispatch.batteryOutNumber}</Descriptions.Item>
        <Descriptions.Item label="Bike Type">{dispatch.bike}</Descriptions.Item>
        <Descriptions.Item label="Rider Name">{dispatch.riderName}</Descriptions.Item>
        <Descriptions.Item label="Rider Phone Nummber">{dispatch.riderPhoneNumber}</Descriptions.Item>
        <Descriptions.Item label="Operator Name">{dispatch.operatorName}</Descriptions.Item>
        <Descriptions.Item label="Platform">{dispatch.platform}</Descriptions.Item>
        <Descriptions.Item label="Franchise">{dispatch.franchise}</Descriptions.Item>
        <Descriptions.Item label="Status">{dispatch.status}</Descriptions.Item>
        <Descriptions.Item label="Reference">{dispatch.reference}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <Button block type="danger" size="large" onClick={handleDelete}>
            Delete Dispatch
          </Button>
        )}
        <ToastContainer />
      </div>
    </Card>
  );
};

export default DetailedDispatch;
