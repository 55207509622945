import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { getOperator } from "../../../../graphql/queries";
import { deleteOperator } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const DetailedOperator = () => {
  const { operatorID } = useParams();
  const navigate = useNavigate();
  const [operator, setOperator] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchOperatorDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getOperator,
          variables: { id: operatorID },
        });
        setOperator(data.getOperator);
      } catch (error) {
        console.error("Error fetching operator details:", error);
      }
    };

    fetchOperatorDetails();
  }, [operatorID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleDelete = async () => {
    try {
      await client.graphql({
        query: deleteOperator,
        variables: { input: { id: operatorID } },
      });
      toast.success("Operator deleted successfully");
      navigate('/operators');
    } catch (error) {
      toast.error("Error deleting operator");
      console.error("Error deleting operator!", error);
    }
  };

  if (!operator) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Operator Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Operator Name">{operator.name}</Descriptions.Item>
        <Descriptions.Item label="Operator Employee ID">{operator.employeeIDNumber}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">{operator.phone_number}</Descriptions.Item>
        <Descriptions.Item label="Operator Email">{operator.email}</Descriptions.Item>
        <Descriptions.Item label="Operator Franchise">{operator.franchise}</Descriptions.Item>
        <Descriptions.Item label="Time Added">{operator.createdAt}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <>
          <Button block type="danger" size="large" onClick={handleDelete}>
            Remove Operator
          </Button>
          <Button block type="primary" size="large" onClick={() => navigate(`/edit-operator/${operatorID}`)}>
          Update Operator
        </Button>
        </>
        )}
        
      </div>
    </Card>
  );
};

export default DetailedOperator;
