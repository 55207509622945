import React, {useState, useEffect } from 'react';
import { Card, Description, Divider, List, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { getDelivery } from "../../../../graphql/queries";
import {  generateClient } from "aws-amplify/api";

const DetailedRequest = () => {
  const { deliveryID } = useParams();
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const client = generateClient();

  useEffect(() =>{
    const fetchDeliveryDetails  = async () =>{
      try{
        const { data } = await client.graphql({
          query: getDelivery,
          variables:{ id: deliveryID}
        });
        setDeliveryDetails(data.getDelivery)
      }
      catch(error){
        console.error("Error fetching delivery details", error)
      }
    }
    fetchDeliveryDetails();
  }, [deliveryID])

  if (!deliveryDetails) {
    return <div>Loading...</div>;
  }
  return (
    <Card title={`Delivery ${deliveryID}`} style={{ margin: 20 }}>
    <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
      <Descriptions.Item label="Recipient Name">{deliveryDetails.recipientName}</Descriptions.Item>
      <Descriptions.Item label="Recipient Phone Number">{deliveryDetails.recipientPhoneNumber}</Descriptions.Item>
      <Descriptions.Item label="Delivery Time">{deliveryDetails.deliveryTime}</Descriptions.Item>
      <Descriptions.Item label="Vehicle Type">{deliveryDetails.vehicleType}</Descriptions.Item>
      <Descriptions.Item label="Delivery Fee">KSH {deliveryDetails.deliveryFee}</Descriptions.Item>
      <Descriptions.Item label="Estimated Delivery Time">{deliveryDetails.estimatedDeliveryTime}</Descriptions.Item>
      <Descriptions.Item label="Payment Method">{deliveryDetails.paymentMethod}</Descriptions.Item>
      <Descriptions.Item label="Origin">{deliveryDetails.origin}</Descriptions.Item>
      <Descriptions.Item label="Destination">{deliveryDetails.destination}</Descriptions.Item>
      <Descriptions.Item label="Pin">{deliveryDetails.pin}</Descriptions.Item>
      <Descriptions.Item label="Status">{deliveryDetails.status}</Descriptions.Item>
      <Descriptions.Item label="Created At">{new Date(deliveryDetails.createdAt).toLocaleString()}</Descriptions.Item>
      <Descriptions.Item label="Updated At">{new Date(deliveryDetails.updatedAt).toLocaleString()}</Descriptions.Item>
    </Descriptions>
    <Divider />
    <Divider />
    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
      <Button block type="danger" size="large">
        Decline Request
      </Button>
      <Button block type="primary" size="large">
        Assign Rider
      </Button>
    </div>
  </Card>
  );
};

const styles = {
  totalSumContainer: {
    display: "flex",
    flexDirection: "row",
  },
  totalPrice: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: "flex",
    paddingBottom: 30,
  },
  button: {
    marginLeft: 20,
    marginRight: 20,
  },
};
export default DetailedRequest;
