import React, { useState, useEffect } from "react";
import { Card, Table, Tooltip, Button } from "antd";
import { listOperators } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const Operators = () => {
  const [operators, setOperators] = useState([]);
  const [filteredOperators, setFilteredOperators] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOperators();
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchOperators() {
    try {
      const operatorData = await client.graphql({ query: listOperators });
      const formattedOperators = operatorData.data.listOperators.items;
      setOperators(formattedOperators);
      setFilteredOperators(formattedOperators);
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = operators.filter(operator =>
      operator.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      operator.phone_number.toString().includes(searchTerm) ||
      operator.franchise.toLowerCase().includes(searchTerm)
    );
    setFilteredOperators(filtered);
  };
  const tableColumns = [
    {
      title: "ID Number",
      dataIndex: "employeeIDNumber",
      key: "employeeIDNumber",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Operators</h2>
        <SearchComponent
          placeholder="Search by name, phone number, franchise"
          onSearch={handleSearch}
        />
        {isAdmin && (
          <Button type="primary" onClick={() => navigate("/add-operator")}>
            Add Operator
          </Button>
        )}
      </div>
      <Card title="Operators List" extra={isAdmin && (
        <Tooltip title="Download as Excel">
          <DownloadOutlined onClick={() => downloadExcel(filteredOperators, "operators")}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
        </Tooltip>)}
        style={{ margin: 20 }}>
        <Table dataSource={filteredOperators} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`operator/${requestItem.id}`);
            },
          })}
        />
      </Card>
    </div>

  );
};

export default Operators;
