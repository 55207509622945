import React from 'react';
import { Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Battery, Bike, Package, Settings, Handshake } from "lucide-react";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      key: "bms",
      label: "BMS",
      icon: <Battery color="white" />,
      children: [
        { key: "/", label: "Dashboard" },
        { key: "/dispatch", label: "Dispatch" },
        { key: "/swap", label: "Swap" },
        { key: "/batteries", label: "Batteries" },
        { key: "/franchises", label: "Franchises" },
        { key: "/operators", label: "Operators" },
        { key: "/track", label: "Track" },
        { key: "/riders", label: "Riders" },
      ],
    },
    {
      key: "delivery",
      label: "Delivery",
      icon: <Package color="white" />,
      children: [
        { key: "/customers", label: "Customers" },
        { key: "/deliveries", label: "Deliveries" },
        // { key: "/products", label: "Products" }
      ],
    },
    {
      key: "fleet",
      label: "Fleet",
      icon: <Bike color="white" />,
      children: [
        { key: "/clients", label: "Clients" },
        { key: "/bikes", label: "Bikes" },
        { key: "/maintenance", label: "Maintenance" },
        { key: "/inventory", label: "Inventory" },
        { key: "/tracking", label: "Tracking" },     
      ],
    },
    {
      key: "sales",
      label: "Sales",
      icon: <Handshake color="white" />,
      children: [
        { key: "/leads", label: "Leads" },
        { key: "/sales/customers", label: "Customers" },
      ],
    },
    { key: "/profile", label: "Settings", icon: <Settings color="white" /> },
  ];

  const onMenuItemClicked = ({ key }) => {
    navigate(key);
  };

  return (
    <Menu
      onClick={onMenuItemClicked}
      selectedKeys={[location.pathname]}
      mode="inline"
      items={menuItems}
      theme="dark"
    />
  );
};

export default SideBar;