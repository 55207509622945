import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api'
import { createOperator, updateOperator, updateFranchise } from "../../../../graphql/mutations";
import { listFranchises, listOperators, getOperator } from "../../../../graphql/queries";

const { Option } = Select;

const OperatorForm = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { operatorID } = useParams();
    const client = generateClient();
    const [franchises, setFranchises] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const franchisesResponse = await client.graphql({ query: listFranchises });
                setFranchises(franchisesResponse.data.listFranchises.items || []);
                
                if (operatorID) {
                    setIsEditMode(true);
                    const { data } = await client.graphql({
                        query: getOperator,
                        variables: { id: operatorID },
                    });
                    const operatorData = data.getOperator;
                    form.setFieldsValue({
                        employeeIDNumber: operatorData.employeeIDNumber,
                        name: operatorData.name,
                        phoneNumber: operatorData.phone_number,
                        email: operatorData.email,
                        franchise: operatorData.franchise,
                    });
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failed to load data. Please try again.");
            }
        };

        fetchData();
    }, [operatorID, form]);

    const handleSubmit = async (values) => {
        try {
            const existingOperatorsResponse = await client.graphql({
                query: listOperators,
                variables: {
                    filter: {
                        or: [
                            { employeeIDNumber: { eq: values.employeeIDNumber } },
                            { phone_number: { eq: values.phoneNumber } },
                            { email: { eq: values.email } }
                        ]
                    }
                }
            });

            const existingOperators = existingOperatorsResponse.data.listOperators.items;

            if (!isEditMode && existingOperators.length > 0) {
                toast.error("An operator with the same Employee ID, phone number, or email already exists.");
                return;
            }

            const operatorData = {
                employeeIDNumber: values.employeeIDNumber,
                name: values.name,
                phone_number: values.phoneNumber,
                email: values.email,
                franchise: values.franchise,
            };

            let operatorResult;
            if (isEditMode) {
                operatorData.id = operatorID;
                operatorResult = await client.graphql({
                    query: updateOperator,
                    variables: { input: operatorData },
                });
                toast.success("Operator updated successfully");
            } else {
                operatorResult = await client.graphql({
                    query: createOperator,
                    variables: { input: operatorData },
                });
                toast.success("Operator added successfully");
            }

            const selectedFranchise = franchises.find(f => f.location === values.franchise);
            if (selectedFranchise) {
                await client.graphql({
                    query: updateFranchise,
                    variables: {
                        input: {
                            id: selectedFranchise.id,
                            operator: isEditMode ? operatorResult.data.updateOperator.name : operatorResult.data.createOperator.name
                        }
                    }
                });
                toast.success("Franchise updated with new operator");
            }

            navigate("/operators");
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("An error occurred while processing the operator. Please try again.");
        }
    }

    const headingText = isEditMode ? "Edit Your Operator Below:" : "Add an Operator Below:";
    const buttonText = isEditMode ? "Update Operator" : "Add Operator";

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", height: "100vh" }}>
            <h3>{headingText}</h3>
            <Form
                form={form}
                name="operatorForm"
                onFinish={handleSubmit}
                style={{ width: "40%" }}
            >
                <Form.Item
                    name="employeeIDNumber"
                    rules={[{ required: true, message: "Please input the operator's Employee ID number!" }]}
                >
                    <Input placeholder="Operator Employee ID" />
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please input the operator name!" }]}
                >
                    <Input placeholder="Operator Name" />
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    rules={[{ required: true, message: "Please input the operator phone number!" }]}
                >
                    <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Operator Phone Number" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: "Please input the operator email!" },
                        { type: 'email', message: "Please enter a valid email address!" }
                    ]}
                >
                    <Input placeholder="Operator Email" />
                </Form.Item>
                <Form.Item
                    name="franchise"
                    rules={[{ required: true, message: "Please select the operator's franchise!" }]}
                >
                    <Select placeholder="Franchise Location">
                        {franchises.map((franchise) => (
                            <Option key={franchise.id} value={franchise.location}>
                                {franchise.location}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default OperatorForm;