import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api'
import { createFranchise, updateFranchise } from "../../../../graphql/mutations";
import { getFranchise, listOperators } from "../../../../graphql/queries";
import Autocomplete from "react-google-autocomplete";

const { Option } = Select;

const FranchiseForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { franchiseID } = useParams();
  const [selectedAddress, setSelectedAddress] = useState("");
  const [operators, setOperators] = useState([]);
  const client = generateClient();
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const operatorsResponse = await client.graphql({ query: listOperators });
        setOperators(operatorsResponse.data.listOperators.items || []);
        if (franchiseID) {
          setIsEditMode(true);
          const { data } = await client.graphql({
            query: getFranchise,
            variables: { id: franchiseID },
          });
          const franchiseData = data.getFranchise;
          form.setFieldsValue({
            location: franchiseData.location,
            address: franchiseData.address,
            operator: franchiseData.operator,
            phoneNumber: franchiseData.contactNumber
          });
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
    };

    fetchOperators();
  }, [franchiseID, form]);

  const handleSubmit = async (values) => {
    try {
      const franchiseData = {
        location: values.location,
        address: selectedAddress,
        contactNumber: values.phoneNumber,
        operator: values.operator,
      };
      if (isEditMode) {
        await client.graphql({
          query: updateFranchise,
          variables: { input: { id: franchiseID, ...franchiseData } },
        });
        toast.success("Franchise updated successfully");
      } else {
        await client.graphql({
          query: createFranchise,
          variables: { input: franchiseData },
        });
        toast.success("Franchise added successfully");
      }
      navigate("/franchises");
    }
    catch (error) {
      console.error(error);
      toast.error("An error occurred while adding the franchise");
    }
  }

  const headingText = isEditMode ? "Update Your Franchise Below:" : "Add a Franchise Below:";
  const buttonText = isEditMode ? "Update Franchise" : "Add Franchise";

  return (

    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", height: "100vh" }}>
      <h3>{headingText}</h3>
      <Form
        form={form}
        name="franchiseForm"
        onFinish={handleSubmit}
        initialValues={{
          location: "",
          address: "",
          phoneNumber: "",
        }}
        style={{ width: "40%" }}
      >
        <Form.Item
          name="location"
          rules={[{ required: true, message: "Please input the franchise location!" }]}
        >
          <Input placeholder="Franchise Name" />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[{ required: true, message: "Please input the franchise address!" }]}
        >
          <Autocomplete
            // apiKey='AIzaSyCYcFKCqGzjYMqYkkAH_Zz6MJlOIif-sSw'
            apiKey='AIzaSyBeBlU--S2r_l-slQXmPR1UoNDlXpffxxo'
            style={{ width: "100%", height: "35px", borderRadius: "5px", borderWidth: "1px" }}
            onPlaceSelected={(place) => {
              const fullAddress = place.formatted_address || place.name;
              setSelectedAddress(fullAddress);
              form.setFieldsValue({ address: fullAddress });
            }}
            options={{
              types: ['establishment']
            }}
          />
        </Form.Item>
        {isEditMode && (
          <Form.Item
            name="operator"
            rules={[{ required: true, message: "Please select an operator!" }]}
          >
            <Select placeholder="Select an operator">
              {operators.map((operator) => (
                <Option key={operator.id} value={operator.id}>
                  {operator.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: "Please input the phone number!" }]}
        >
          <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Franchise Phone Number" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FranchiseForm;
