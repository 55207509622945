import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { getFranchise } from "../../../../graphql/queries";
import { deleteFranchise } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const DetailedFranchise = () => {
  const { franchiseID } = useParams();
  const navigate = useNavigate();
  const [franchise, setFranchise] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchFranchiseDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getFranchise,
          variables: { id: franchiseID },
        });
        setFranchise(data.getFranchise);
      } catch (error) {
        console.error("Error fetching franchise details:", error);
      }
    };

    fetchFranchiseDetails();
  }, [franchiseID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleDelete = async () => {
    try {
      await client.graphql({
        query: deleteFranchise,
        variables: { input: { id: franchiseID } },
      });
      toast.success("Franchise deleted successfully");
      navigate('/franchises');
    } catch (error) {
      toast.error("Error deleting franchise");
      console.error("Error deleting franchise!", error);
    }
  };

  if (!franchise) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Franchise Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Franchise Location">{franchise.location}</Descriptions.Item>
        <Descriptions.Item label="Franchise Address">{franchise.address}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">{franchise.contactNumber}</Descriptions.Item>
        <Descriptions.Item label="Franchise Operator">{franchise.operator}</Descriptions.Item>
        <Descriptions.Item label="Time Added">{franchise.createdAt}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <>
            <Button block type="danger" size="large" onClick={handleDelete}>
              Remove Franchise
            </Button>
            <Button block type="primary" size="large" onClick={() => navigate(`/edit-franchise/${franchiseID}`)}>
              Update Franchise
            </Button>
          </>
        )}
      </div>
    </Card>
  );
};

export default DetailedFranchise;
