import React, { useState, useEffect } from "react";
import { Card, Table } from "antd";
import { listSalesCustomers } from "../../../graphql/queries"; // Assuming listSalesCustomers query is defined in your queries
import { generateClient } from "aws-amplify/api"; // Assuming you're using AWS Amplify
import SearchComponent from "../../../components/searchBar"; // Assuming you have a SearchComponent

const client = generateClient();

const SalesCustomers = () => {
  const [salesCustomers, setSalesCustomers] = useState([]);
  const [filteredSalesCustomers, setFilteredSalesCustomers] = useState([]);

  useEffect(() => {
    fetchSalesCustomers();
  }, [client]);

  async function fetchSalesCustomers() {
    try {
      const salesData = await client.graphql({ query: listSalesCustomers });
      const formattedSalesCustomers = salesData.data.listSalesCustomers.items;
      setSalesCustomers(formattedSalesCustomers);
      setFilteredSalesCustomers(formattedSalesCustomers);
    } catch (error) {
      console.error("Error fetching sales customers:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = salesCustomers.filter(customer =>
      customer.customerName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSalesCustomers(filtered);
  };

  const tableColumns = [
    {
      title: "Customer ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Customer Type",
      dataIndex: "customerType",
      key: "customerType",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
    },
    {
      title: "County",
      dataIndex: "county",
      key: "county",
    },
    {
      title: "Town",
      dataIndex: "town",
      key: "town",
    },
    {
      title: "Sales Type",
      dataIndex: "salesType",
      key: "salesType",
    },
    {
      title: "Industry",
      dataIndex: "customerIndustry",
      key: "customerIndustry",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "No. of Bikes",
      dataIndex: "noOfBikes",
      key: "noOfBikes",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Comment",
      dataIndex: "Comment",
      key: "Comment",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Sales Customers</h2>
        <SearchComponent
          placeholder="Search by customer name"
          onSearch={handleSearch}
        />
      </div>
      <Card title="Sales Customers" style={{ margin: 20 }}>
        <Table dataSource={filteredSalesCustomers} columns={tableColumns} rowKey="id" />
      </Card>
    </div>
  );
};

export default SalesCustomers;
