import React, { useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient} from 'aws-amplify/api'
import { createDelivery, updateDelivery } from "../../../../graphql/mutations";

const { Option } = Select;

const DeliveryForm = () => {
 const [form] = Form.useForm();
 const navigate = useNavigate();
 const location = useLocation();
 const delivery = location.state?.delivery;
 const client = generateClient();

 useEffect(() => {
  if (delivery) {
    form.setFieldsValue({
        recipientName: delivery.recipientName,
        recipientPhoneNumber: delivery.recipientPhoneNumber,
        instructionsToRider: delivery.instructionsToRider,
        vehicleType: delivery.vehicleType,
        deliveryFee: delivery.deliveryFee.toString(),
        estimatedDeliveryTime: delivery.estimatedDeliveryTime,
        paymentMethod: delivery.paymentMethod,
        origin: delivery.origin,
        destination: delivery.destination,
        pin: delivery.pin,
        status: delivery.status,
    });
  }
}, [delivery, form]);

 const handleSubmit = async (values) => {
    try {
      const deliveryData = {
        recipientName: values.recipientName,
        recipientPhoneNumber: values.recipientPhoneNumber,
        instructionsToRider: values.instructionsToRider,
        vehicleType: values.vehicleType,
        deliveryFee: parseFloat(values.deliveryFee),
        estimatedDeliveryTime: values.estimatedDeliveryTime,
        paymentMethod: values.paymentMethod,
        origin: values.origin,
        destination: values.destination,
        pin: parseInt(values.pin),
        status: values.status || "PENDING", // Default status
      };
      if (delivery) {
        await client.graphql({
          query: updateDelivery,
          variables: { input: {id: delivery.id, ...deliveryData }},
        });
        toast.success("Delivery updated successfully");
      } else{
      await client.graphql({
        query: createDelivery,
        variables: { input: deliveryData },
      });
      toast.success("Delivery added successfully");
      }
      navigate("/deliveries");
    }
      catch(error){
        console.error(error);
        toast.error("An error occurred while adding the delivery");
      }
    }

 const isEditMode =!!delivery;
 const headingText = isEditMode? "Edit Your Delivery Below:" : "Create a Delivery Request Below:";
 const buttonText = isEditMode? "Edit Delivery" : "Create Delivery";

 return (
   
    <div style={{ display: "flex", flexDirection:"column",marginTop:20, gap:20, alignItems:"center", height: "100vh" }}>
         <h3>{headingText}</h3>
         <Form
        form={form}
        name="deliveryForm"
        onFinish={handleSubmit}
        initialValues={{
          deliveryFee: 0,
          status: "PENDING",
        }}
        style={{ width: "60%" }}
      >
        <Form.Item
          name="recipientName"
          rules={[{ required: true, message: "Please input the recipient's name!" }]}
        >
          <Input placeholder="Recipient Name" />
        </Form.Item>
        <Form.Item
          name="recipientPhoneNumber"
          rules={[{ required: true, message: "Please input the recipient's phone number!" }]}
        >
          <Input placeholder="Recipient Phone Number" />
        </Form.Item>
        <Form.Item name="instructionsToRider">
          <Input.TextArea placeholder="Instructions to Rider" />
        </Form.Item>
        <Form.Item name="vehicleType">
          <Select placeholder="Vehicle Type">
            <Option value="BICYCLE">Bicycle</Option>
            <Option value="MOTORCYCLE">Motorcycle</Option>
            <Option value="CAR">Car</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="deliveryFee"
          rules={[{ required: true, message: "Please input the delivery fee!" }]}
        >
          <Input type="number" placeholder="Delivery Fee" />
        </Form.Item>
        <Form.Item name="estimatedDeliveryTime">
          <Input placeholder="Estimated Delivery Time" />
        </Form.Item>
        <Form.Item name="paymentMethod">
          <Select placeholder="Payment Method">
            <Option value="CASH">Cash</Option>
            <Option value="CARD">Card</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="origin"
          rules={[{ required: true, message: "Please input the origin!" }]}
        >
          <Input placeholder="Origin" />
        </Form.Item>
        <Form.Item
          name="destination"
          rules={[{ required: true, message: "Please input the destination!" }]}
        >
          <Input placeholder="Destination" />
        </Form.Item>
        <Form.Item name="pin">
          <Input type="number" placeholder="PIN" />
        </Form.Item>
        {isEditMode && (
          <Form.Item name="status">
            <Select placeholder="Status">
              <Option value="PENDING">Pending</Option>
              <Option value="IN_PROGRESS">In Progress</Option>
              <Option value="COMPLETED">Completed</Option>
              <Option value="CANCELLED">Cancelled</Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </Form>
    </div>
 );
};

export default DeliveryForm;
