import React, { useState, useEffect } from "react";
import { Card, Divider, Button, Descriptions } from "antd";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { toast } from "react-toastify";
import { getRider } from "../../../../graphql/queries";
import { deleteRider } from "../../../../graphql/mutations";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();

const DetailedRider = () => {
  const { riderID } = useParams();
  const navigate = useNavigate();
  const [rider, setRider] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchRiderDetails = async () => {
      try {
        const { data } = await client.graphql({
          query: getRider,
          variables: { id: riderID },
        });
        setRider(data.getRider);
      } catch (error) {
        console.error("Error fetching rider details:", error);
      }
    };

    fetchRiderDetails();
  }, [riderID]);

  useEffect(() => {
    checkUserRole();
  }, [client]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleDelete = async () => {
    try {
      await client.graphql({
        query: deleteRider,
        variables: { input: { id: riderID } },
      });
      toast.success("Rider deleted successfully");
      navigate('/riders');
    } catch (error) {
      toast.error("Error deleting rider");
      console.error("Error deleting rider!", error);
    }
  };

  if (!rider) {
    return <div>Loading...</div>;
  }

  return (
    <Card title={`Rider Details`} style={{ margin: 20 }}>
      <Descriptions bordered column={{ lg: 1, md: 1, sm: 1 }}>
        <Descriptions.Item label="Rider Name">{rider.name}</Descriptions.Item>
        <Descriptions.Item label="Rider National ID">{rider.nationalIDNumber}</Descriptions.Item>
        <Descriptions.Item label="Phone Number">{rider.phone_number}</Descriptions.Item>
        <Descriptions.Item label="Vehicle Type">{rider.vehicleType}</Descriptions.Item>
        <Descriptions.Item label="Rider Franchise">{rider.franchise}</Descriptions.Item>
        <Descriptions.Item label="Rider's Fleet">{rider.fleet}</Descriptions.Item>
        <Descriptions.Item label="Rider Status">{rider.status}</Descriptions.Item>
        <Descriptions.Item label="Time Added">{rider.createdAt}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-around", padding: 20, gap: 50 }}>
        {isAdmin && (
          <>
          <Button block type="danger" size="large" onClick={handleDelete}>
            Remove Rider
          </Button>
          <Button block type="primary" size="large" onClick={() => navigate(`/edit-rider/${riderID}`)}>
          Update Rider
        </Button>
        </>
        )}
        
      </div>
    </Card>
  );
};

export default DetailedRider;
