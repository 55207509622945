import React, { createContext, useState } from 'react';

const BatteryContext = createContext();

const BatteryProvider = ({ children }) => {
  const [batteries, setBatteries] = useState([]);

  return (
    <BatteryContext.Provider value={{ batteries, setBatteries }}>
      {children}
    </BatteryContext.Provider>
  );
};

export { BatteryContext, BatteryProvider };
