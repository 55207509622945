import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, Select } from "antd";
import { listRiders } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';

const client = generateClient();

const Riders = () => {
  const [riders, setRiders] = useState([]);
  const [filteredRiders, setFilteredRiders] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [franchises, setFranchises] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const navigate = useNavigate();

  const renderRiderStatus = (requestStatus) => {
    if (requestStatus === "approved") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "rejected") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchRiders();
    checkUserRole();
  }, [client]);

  const extractFranchises = (riders) => {
    const uniqueFranchises = [...new Set(riders.map(rider => rider.franchise))];
    setFranchises(uniqueFranchises);
  };

  async function fetchRiders() {
    let allRiders = [];
    let nextToken = null;
    do {
      const riderData = await client.graphql({ 
        query: listRiders,
        variables: { limit: 1000, nextToken: nextToken }
      });
      allRiders = [...allRiders, ...riderData.data.listRiders.items];
      nextToken = riderData.data.listRiders.nextToken;
    } while (nextToken);
  
    setRiders(allRiders);
    setFilteredRiders(allRiders);
    extractFranchises(allRiders);
  }

  const handleFranchiseChange = (value) => {
    setSelectedFranchise(value);
    if (value) {
      const filtered = riders.filter(rider => rider.franchise === value);
      setFilteredRiders(filtered);
    } else {
      setFilteredRiders(riders);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = riders.filter(rider =>
      rider.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      rider.phone_number.toString().includes(searchTerm) ||
      rider.status.toLowerCase().includes(searchTerm)
    );
    setFilteredRiders(filtered);
  };

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);
    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "ID Number",
      dataIndex: "nationalIDNumber",
      key: "nationalIDNumber",
    },
    {
      title: "Bike Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
    },
    {
      title: "Fleet",
      dataIndex: "fleet",
      key: "fleet",
    },
    {
      title: "Location",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => renderRiderStatus(status),
    },
    
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Riders</h2>
        <SearchComponent
          placeholder="Search by rider name, status, phone number"
          onSearch={handleSearch}
        />
        {isAdmin && (
          <Button type="primary" onClick={() => navigate("/add-rider")}>
            Add Rider
          </Button>
        )}
      </div>
      <Card title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>Riders List</span>
          <Select
            style={{ width: 200, marginRight: 20 }}
            placeholder="Filter by franchise"
            onChange={handleFranchiseChange}
            allowClear
          >
            {franchises.map(franchise => (
              <Select.Option key={franchise} value={franchise}>{franchise}</Select.Option>
            ))}
          </Select>
        </div>
      } extra={isAdmin && (
        <Tooltip title="Download as Excel">
          <DownloadOutlined onClick={() => downloadExcel(filteredRiders, "riders")}
            style={{ fontSize: '20px', cursor: 'pointer' }}
          />
        </Tooltip>)}
        style={{ margin: 20 }}>
        <Table dataSource={filteredRiders} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`rider/${requestItem.id}`);
            },
          })}
        />
      </Card>
    </div>

  );
};

export default Riders;
