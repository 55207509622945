import React, { useEffect, useState } from 'react';
import { Card, Typography, Button } from 'antd';
import { LogoutOutlined, UserAddOutlined } from '@ant-design/icons';
import { getCurrentUser, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;

const Profile = ({ handleSignOut }) => {
  const [fullName, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [user, setUser] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
    checkUserRole();
  }, []);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';
      const groups = session.tokens.accessToken.payload['cognito:groups'];
      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);
    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  const handleNavigateToSignUp = () => {
    navigate("/signup");
  }

  const fetchUserProfile = async () => {
    try {
      const currentUser = await getCurrentUser();
      const session = await fetchAuthSession();
      const idTokenString = typeof session.tokens.idToken.toString === 'function' ? session.tokens.idToken.toString() : session.tokens.idToken;
      const idTokenPayload = jwtDecode(idTokenString);
      setUser(currentUser);
      setFullname(idTokenPayload.name);
      setEmail(idTokenPayload.email);
      setPhoneNumber(idTokenPayload.phone_number);
    } catch (error) {
      console.error("Failed to fetch Account details", error);
    }
  }

  return (
    <Card style={{ marginTop: 20 }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title level={4}>Welcome, {fullName}</Title>
        <Paragraph>Your Email: {email}</Paragraph>
        <Paragraph>Your Phone Number: {phoneNumber}</Paragraph>
        <Paragraph>
          {isAdmin ? "Signed in as eWAKA Admin" : "Signed in as Swap Station Operator"}
        </Paragraph>
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          <Button 
            type="primary" 
            icon={<LogoutOutlined />} 
            onClick={() => handleSignOut(() => toast.loading("Signing out..."))}
          >
            Logout
          </Button>
          <Button 
            type="default" 
            icon={<UserAddOutlined />} 
            onClick={handleNavigateToSignUp}
          >
            Add User 
          </Button>
        </div>
      </div>
      <ToastContainer />
    </Card>
  );
};

export default Profile;