import React from 'react';
import { Upload, message, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const ExcelUploader = ({ onUpload, uploadProgress }) => {
  const props = {
    name: 'file',
    multiple: false,
    accept: '.xlsx, .xls',
    beforeUpload: (file) => {
      onUpload(file);
      return false; // Prevent default upload behavior
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single Excel file upload. Strictly prohibit from uploading company data or other
          sensitive files.
        </p>
      </Dragger>
      {uploadProgress > 0 && uploadProgress < 100 && (
        <Progress percent={uploadProgress} status="active" style={{ marginTop: '20px' }} />
      )}
    </div>
  );
};

export default ExcelUploader;